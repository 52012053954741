import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { AccountTree, Clear } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";
import { useFormik } from "formik";
import { addProject, updatedProject } from "../../api/cvApi";
import { useAlert } from "../../utils/alertContext";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import * as Yup from "yup";

interface ProjectModalProps {
  open: boolean;
  handleClose: () => void;
  cvId: number;
  data?: any;
  onProjectUpdated?: () => void;
}

const ProjectModal: React.FC<ProjectModalProps> = ({
  open,
  handleClose,
  cvId,
  data,
  onProjectUpdated = () => {},
}) => {
  const [ongoingProject, setOngoingProject] = useState(false);
  const { showAlert } = useAlert();
  const [errorMessage, setErrorMessage] = useState("");

  const initialValues = {
    name: data ? data.name : "",
    client: data ? data.client : "",
    role: data ? data.role : "",
    startDate: data ? dayjs(data.startDate) : null,
    endDate: data ? (data.endDate ? dayjs(data.endDate) : null) : null,
    shortDescriptionOfTheProject: data ? data.shortDescriptionOfTheProject : "",
    descriptionOfResponsibilities: data
      ? data.descriptionOfResponsibilities
      : "",
    techStack: data ? data.techStack : "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`${translationsEn.validation.required}`),
    client: Yup.string().required(`${translationsEn.validation.required}`),
    role: Yup.string().required(`${translationsEn.validation.required}`),
    startDate: Yup.date().required(`${translationsEn.validation.required}`),
    endDate: ongoingProject
      ? Yup.date().nullable().notRequired()
      : Yup.date().required(`${translationsEn.validation.required}`),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const formatDate = (date: dayjs.Dayjs | null) =>
        date ? date.format("YYYY-MM") : null;

      const startDate = formatDate(values.startDate);
      const endDate = ongoingProject ? null : formatDate(values.endDate);

      const endpointPayload = {
        cvId,
        client: values.client,
        descriptionOfResponsibilities: values.descriptionOfResponsibilities,
        name: values.name,
        startDate,
        endDate,
        role: values.role,
        shortDescriptionOfTheProject: values.shortDescriptionOfTheProject,
        techStack: values.techStack,
      };

      const saveProject = data ? updatedProject : addProject;

      saveProject({
        id: data ? data.id : 0,
        ...endpointPayload,
      })
        .then((response) => {
          showAlert(`${translationsEn.cvModals.successProject}`, "success");
          onProjectUpdated();
          handleClose();
        })
        .catch((error) => {
          showAlert(
            error.response?.data?.error || "An error occurred",
            "error"
          );
          setErrorMessage(error.response?.data?.error || "An error occurred");
        });
    },
  });

  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open]);

  useEffect(() => {
    if (data) {
      formik.setValues({
        name: data.name || "",
        client: data.client || "",
        role: data.role || "",
        startDate: data.startDate ? dayjs(data.startDate) : null,
        endDate: data.endDate ? dayjs(data.endDate) : null,
        shortDescriptionOfTheProject: data.shortDescriptionOfTheProject || "",
        descriptionOfResponsibilities: data.descriptionOfResponsibilities || "",
        techStack: data.techStack || "",
      });
      setOngoingProject(data.endDate === null);
    }
  }, [data]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOngoingProject(event.target.checked);
    if (event.target.checked) {
      formik.setFieldValue("endDate", null);
    }
  };

  const handleSave = () => {
    formik.handleSubmit();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            width: "60%",
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <AccountTree style={{ marginRight: "5px" }} />
              <Typography>{translationsEn.cvModals.addProject}</Typography>
            </Box>

            <Clear onClick={handleClose} />
          </Box>
          <hr />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "20px",
            }}
          >
            <TextField
              id="name"
              name="name"
              label={translationsEn.cvModals.title}
              variant="standard"
              required
              value={formik.values.name}
              onChange={formik.handleChange}
              style={{
                margin: "10px",
                width: "100%",
              }}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={
                formik.touched.name &&
                formik.errors.name &&
                (formik.errors.name as string)
              }
            />

            <TextField
              id="client"
              name="client"
              label={translationsEn.cvModals.compay}
              variant="standard"
              required
              value={formik.values.client}
              onChange={formik.handleChange}
              style={{
                margin: "10px",
                width: "100%",
              }}
              error={formik.touched.client && Boolean(formik.errors.client)}
              helperText={
                formik.touched.client &&
                formik.errors.client &&
                (formik.errors.client as string)
              }
            />

            <TextField
              id="role"
              name="role"
              label={translationsEn.cvModals.role}
              variant="standard"
              required
              value={formik.values.role}
              onChange={formik.handleChange}
              style={{
                margin: "10px",
                width: "100%",
              }}
              error={formik.touched.role && Boolean(formik.errors.role)}
              helperText={
                formik.touched.role &&
                formik.errors.role &&
                (formik.errors.role as string)
              }
            />

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <FormControl style={{ width: "100%" }}>
                <DatePicker
                  label={translationsEn.cvModals.startDate}
                  views={["month", "year"]}
                  value={
                    formik.values.startDate
                      ? dayjs(formik.values.startDate)
                      : null
                  }
                  onChange={(date) =>
                    formik.setFieldValue("startDate", date ? dayjs(date) : null)
                  }
                  slotProps={{
                    textField: {
                      helperText:
                        formik.touched.startDate &&
                        formik.errors.startDate &&
                        (formik.errors.startDate as string),
                      error: Boolean(
                        formik.touched.startDate && formik.errors.startDate
                      ),
                    },
                  }}
                  sx={{ width: "100%" }}
                />
              </FormControl>
            </Box>

            <FormControlLabel
              style={{ margin: "10px", width: "30%" }}
              control={
                <Checkbox
                  checked={ongoingProject}
                  onChange={handleCheckboxChange}
                />
              }
              label={translationsEn.cvModals.onGoingProject}
            />

            {!ongoingProject && (
              <FormControl style={{ width: "100%" }}>
                <DatePicker
                  label={translationsEn.cvModals.endDate}
                  views={["month", "year"]}
                  value={
                    formik.values.endDate ? dayjs(formik.values.endDate) : null
                  }
                  onChange={(date) =>
                    formik.setFieldValue("endDate", date ? dayjs(date) : null)
                  }
                  slotProps={{
                    textField: {
                      helperText:
                        formik.touched.endDate &&
                        formik.errors.endDate &&
                        (formik.errors.endDate as string),
                      error: Boolean(
                        formik.touched.endDate && formik.errors.endDate
                      ),
                    },
                  }}
                  sx={{ width: "100%" }}
                />
              </FormControl>
            )}

            <TextField
              id="shortDescriptionOfTheProject"
              name="shortDescriptionOfTheProject"
              label={translationsEn.cvModals.shortDescription}
              variant="standard"
              value={formik.values.shortDescriptionOfTheProject}
              onChange={formik.handleChange}
              style={{
                margin: "10px",
                width: "100%",
              }}
            />

            <TextField
              id="descriptionOfResponsibilities"
              name="descriptionOfResponsibilities"
              label={translationsEn.cvModals.responsibilitiesDescriptiom}
              variant="standard"
              value={formik.values.descriptionOfResponsibilities}
              onChange={formik.handleChange}
              style={{
                margin: "10px",
                width: "100%",
              }}
            />

            <TextField
              id="techStack"
              name="techStack"
              label={translationsEn.cvModals.techStack}
              variant="standard"
              value={formik.values.techStack}
              onChange={formik.handleChange}
              style={{
                margin: "10px",
                width: "100%",
              }}
            />

            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                onClick={handleSave}
                variant="contained"
                sx={{
                  background: themeOptions.palette.confirm.main,
                }}
              >
                {translationsEn.buttons.save}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ProjectModal;
